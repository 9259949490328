import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Container, Center } from '../../styles/TermsOfUseStyle';
import ScrollToTop from '../../utils/ScrollToTop';

const TermsOfUse = () => {
  return (
    <>
      <ScrollToTop />
      <Container>
        <Header />
        <Center>
          <h1>Terms of Use</h1>
          <h2><span>1.</span> Introduction</h2>
          <p>Welcome to BrighterHeads. By using the BrighterHeads website ('Site') and our services,
            you agree to comply with these Terms of Use. We reserve the right to modify these terms
            at any time and your continued use of the Site following such changes will indicate your
            acceptance of these updated terms. The Website, its features, its Content, and its related
            services come "as is" with no warranty of any kind.</p>

          <h2><span>2.</span> Ownership</h2>
          <p>All content on the Website, including, but not limited to, logo, artwork and identity
            created, wearables created, and text ("Content") is the property of BrighterHeads and
            is protected by copyright, trademark, and other intellectual property laws.</p>

          <h2><span>3.</span> Intellectual Property</h2>
          <p>All original digital art, Non-Fungible Tokens (NFTs), and wearables presented in our
            galleries was created by BrighterHeads and are protected by national and international
            copyright and other intellectual property laws.</p>

          <h2><span>3.1</span> Prohibited Use</h2>
          <p>The unauthorized commercial use, download, duplication, or modification of our digital
            content without our express permission is strictly prohibited. Violators may be subject
            to civil and criminal penalties under copyright laws.</p>

          <h2><span>4.</span> Artworks and NFTs</h2>
          <p>Part of our digital art was created using a unique digital pointillism technique and
            is derived from multiple public domain works, which are properly credited. In purchasing
            or receiving from us an NFT, you are acquiring ownership of a unique piece of digital
            art from the BrighterHeads collection. The NFT does not give you copyright or reproduction
            rights to the art.</p>

          <h2><span>5.</span> Whitelist and NFT Distribution</h2>
          <p>Applying for whitelist registration does not guarantee receipt of an NFT. We plan
            distribute 500 NFTs randomly among all registered users, which might exceed 500
            individuals. The process of allocation is planned to be random, and being whitelisted
            does not guarantee you will receive an NFT.</p>

          <h2><span>6.</span> Limitation of Liability</h2>
          <p>BrighterHeads will not be liable under any circumstances for any loss of income,
            loss of profits, loss of contracts, loss of data, or any indirect or consequential
            loss or damage of any kind, howsoever arising. Our liability is explicitly limited
            to the extent permitted by law, and we expressly exclude any liability for personal
            injury or death caused by our negligence, as there is no reasonable foreseeability
            of such harm in relation to our artistic project.</p>
          <p>Please review this agreement periodically for changes. Your continued use of the
            Site constitutes your acceptance of any changes to this agreement.</p>
          <p>By using this Site, you signify your acceptance of these Terms of Use details. If
            you do not agree to this, please do not use our Site. Your continued use of the Site
            following changes to this policy will be deemed acceptance of those changes.</p>
        </Center>
        <Footer />
      </Container>
    </>
  )
};

export default TermsOfUse;